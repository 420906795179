import React, { useContext,useEffect,useState } from "react";
import { StoreContext } from "context/store-context";
import CloseImage from "../images/cart_cross.png";
import DeleteImage from "../images/delete-item.svg";
import { TailSpin, ThreeDots } from  'react-loader-spinner'

// write a useEffect to modify the cart list when the country is changed
const Cart = () => {
        
  const { toggleCart, buyNow, updateCartLineItem,setCartId, removeCartLineItem, loading ,getCountry,new_computedCart,cartId,cartList} = useContext(StoreContext);
  const [cart, setCart] = useState([]);

  React.useEffect(() => {
    const storedCartId = localStorage.getItem('shopify_cart_id');
    if (storedCartId) {
      setCartId(storedCartId);
    }
  }, []);
  useEffect(() => {
    const fetchCart = async () => {
      if (cartId) { // Check if cartId is defined
        const cartData = await new_computedCart();
        setCart(cartData);
      } else {
        setCart([]); // explicitly handle undefined case
      }
    };
    fetchCart();
  }, [cartId, new_computedCart]);

  useEffect(() => {
    setCart(cartList);
  }, [cartList]); 
  const grandTotal = () => {
    return cart.reduce((acc, item) => acc + item.price * item.quantity, 0);
  };
  return (
    <div className="cart">
      <div className="cart__header">
        <div className="cart__header__heading">Cart</div>
        <span>
          <img
            className="cart__header__cross"
            src={CloseImage}
            alt="close"
            onClick={toggleCart}
          />
        </span>
      </div>
      <div className="cart__items">
        {cart.map((item, index) => (
          
          <div className="cart__items__item" key={index}>
            <img
              className="cart__items__item__image"
              src={item.image}
              alt=""
            />
            <div className="cart__items__item__data">
              <h1>{item.title}</h1>
              <div style={{display:'flex'}}>

              <h5>
                {
                  getCountry() === "IN"?
                  new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                }).format(item.price)
                :
                new Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "USD",
              }).format(item.price)
              }
              </h5>
              </div>
              <div className="cart__items__item__data__price">
                <div className="cart__items__item__data__price__left">
                  <div className="cart_modifier" onClick={() =>  updateCartLineItem(cartId,item.id, item.quantity - 1)}>-</div>
                  {
                    loading?
                    <TailSpin
                      height="20"
                      width="20"
                      color="#142b6f"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    /> :
                    <h5>{item.quantity}</h5>
                  }
                  <div className="cart_modifier" onClick={() => updateCartLineItem(cartId,item.id, item.quantity + 1)}>+</div>
                </div>
                <div className="cart__items__item__data__price__right">
                  <img className="cart_delete_item" src={DeleteImage} onClick={() =>  removeCartLineItem(cartId,item.id)}></img>
                </div>
              </div>
            </div>
          </div>
        ))}
        {
          cart.length===0 && 
          <div className="cart__items__empty_placeholder">
            You currently have no products in your cart.
          </div>
        }
      </div>
      <div className="cart__footer">
          <h1 style={{marginLeft:'8px'}}>
            {
              getCountry() === "IN"?
            new Intl.NumberFormat("en-IN", {
              style: "currency",
              currency: "INR",
            }).format(grandTotal())
            :
            new Intl.NumberFormat("en-IN", {
              style: "currency",
              currency: "USD",
            }).format(grandTotal())
          }
          </h1>
        <button onClick={buyNow}>
          {
           loading ?
            <ThreeDots 
              height="20" 
              width="30" 
              radius="5"
              color="#fff" 
              ariaLabel="three-dots-loading"
              wrapperStyle={{justifyContent: 'center'}}
              wrapperClassName=""
              visible={true}
              /> :
              'Checkout'
          }
        </button>
      </div>
    </div>
  );
};

export default Cart;
