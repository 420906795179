exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-adults-jsx": () => import("./../../../src/pages/adults.jsx" /* webpackChunkName: "component---src-pages-adults-jsx" */),
  "component---src-pages-adults-kit-jsx": () => import("./../../../src/pages/adults-kit.jsx" /* webpackChunkName: "component---src-pages-adults-kit-jsx" */),
  "component---src-pages-blogs-jsx": () => import("./../../../src/pages/blogs.jsx" /* webpackChunkName: "component---src-pages-blogs-jsx" */),
  "component---src-pages-blogs-sanity-all-blogs-slug-current-jsx": () => import("./../../../src/pages/blogs/{sanityAllBlogs.slug__current}.jsx" /* webpackChunkName: "component---src-pages-blogs-sanity-all-blogs-slug-current-jsx" */),
  "component---src-pages-cancellations-and-refunds-js": () => import("./../../../src/pages/cancellations-and-refunds.js" /* webpackChunkName: "component---src-pages-cancellations-and-refunds-js" */),
  "component---src-pages-dna-kit-insights-jsx": () => import("./../../../src/pages/dna-kit-insights.jsx" /* webpackChunkName: "component---src-pages-dna-kit-insights-jsx" */),
  "component---src-pages-dna-kit-jsx": () => import("./../../../src/pages/dna-kit.jsx" /* webpackChunkName: "component---src-pages-dna-kit-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-follow-up-program-follow-up-design-jsx": () => import("./../../../src/pages/follow-up-program/followUpDesign.jsx" /* webpackChunkName: "component---src-pages-follow-up-program-follow-up-design-jsx" */),
  "component---src-pages-follow-up-program-follow-up-landing-jsx": () => import("./../../../src/pages/follow-up-program/followUpLanding.jsx" /* webpackChunkName: "component---src-pages-follow-up-program-follow-up-landing-jsx" */),
  "component---src-pages-follow-up-program-follow-up-product-jsx": () => import("./../../../src/pages/follow-up-program/followUpProduct.jsx" /* webpackChunkName: "component---src-pages-follow-up-program-follow-up-product-jsx" */),
  "component---src-pages-follow-up-program-follow-up-we-help-jsx": () => import("./../../../src/pages/follow-up-program/followUpWeHelp.jsx" /* webpackChunkName: "component---src-pages-follow-up-program-follow-up-we-help-jsx" */),
  "component---src-pages-follow-up-program-jsx": () => import("./../../../src/pages/follow-up-program.jsx" /* webpackChunkName: "component---src-pages-follow-up-program-jsx" */),
  "component---src-pages-gut-balance-program-jsx": () => import("./../../../src/pages/gut-balance-program.jsx" /* webpackChunkName: "component---src-pages-gut-balance-program-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-insights-jsx": () => import("./../../../src/pages/insights.jsx" /* webpackChunkName: "component---src-pages-insights-jsx" */),
  "component---src-pages-insights-kids-jsx": () => import("./../../../src/pages/insights-kids.jsx" /* webpackChunkName: "component---src-pages-insights-kids-jsx" */),
  "component---src-pages-kids-jsx": () => import("./../../../src/pages/kids.jsx" /* webpackChunkName: "component---src-pages-kids-jsx" */),
  "component---src-pages-longevity-jsx": () => import("./../../../src/pages/longevity.jsx" /* webpackChunkName: "component---src-pages-longevity-jsx" */),
  "component---src-pages-longevity-kit-jsx": () => import("./../../../src/pages/longevity-kit.jsx" /* webpackChunkName: "component---src-pages-longevity-kit-jsx" */),
  "component---src-pages-microbiome-dna-health-package-jsx": () => import("./../../../src/pages/microbiome-dna-health-package.jsx" /* webpackChunkName: "component---src-pages-microbiome-dna-health-package-jsx" */),
  "component---src-pages-microbiome-health-package-jsx": () => import("./../../../src/pages/microbiome-health-package.jsx" /* webpackChunkName: "component---src-pages-microbiome-health-package-jsx" */),
  "component---src-pages-microbiome-program-with-coaching-jsx": () => import("./../../../src/pages/microbiome-program-with-coaching.jsx" /* webpackChunkName: "component---src-pages-microbiome-program-with-coaching-jsx" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-sciences-js": () => import("./../../../src/pages/sciences.js" /* webpackChunkName: "component---src-pages-sciences-js" */),
  "component---src-pages-shipping-and-delivery-js": () => import("./../../../src/pages/shipping-and-delivery.js" /* webpackChunkName: "component---src-pages-shipping-and-delivery-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-webinar-2-jsx": () => import("./../../../src/pages/webinar-2.jsx" /* webpackChunkName: "component---src-pages-webinar-2-jsx" */),
  "component---src-pages-webinar-jsx": () => import("./../../../src/pages/webinar.jsx" /* webpackChunkName: "component---src-pages-webinar-jsx" */)
}

