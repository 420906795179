import * as React from "react";
import { toast } from 'react-toastify'
import {createStorefrontApiClient} from '@shopify/storefront-api-client';
import Cart from '../components/cart'


const new_client_US = createStorefrontApiClient({
  storeDomain: process.env.GATSBY_SHOPIFY_DOMAIN_US,
  apiVersion: '2025-01',
  publicAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN_US,
});
const new_client_IN = createStorefrontApiClient({
  storeDomain: process.env.GATSBY_SHOPIFY_DOMAIN_IN,
  apiVersion: '2025-01',
  publicAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN_IN,
});



const defaultValues = {
  cart: [],
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  getCountry: () => {},
  checkUsBuy:() => {},
  getCartCount:()=>{},
  new_client_IN,
  new_client_US,
  checkout: {
    lineItems: [],
  },
};

export const StoreContext = React.createContext(defaultValues);

const isBrowser = typeof window !== `undefined`;
const localStorageKeyForCheckoutId = `shopify_checkout_id`;
const localStorageKeyForCartId = `shopify_cart_id`;
const localStorageKeyForIsLocationEnabled = `isLocationEnabled`;

const COUNTRY_OPTIONS = ['IN', 'US'];
const localStorageKeyForCountry = 'nh_country';
const DEFAULT_COUNTRY = 'US';

export const StoreProvider = ({ children }) => {
  const [checkout, setCheckout] = React.useState(defaultValues.checkout);
  const [cartId, setCartId] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [didJustAddToCart, setDidJustAddToCart] = React.useState(false);
  const [cartCount, setCartCount] = React.useState(0);
  const [showCart, setShowCart] = React.useState(false);
  const [country, setCountry] = React.useState(DEFAULT_COUNTRY);
  const [usBuy, setUsBuy] = React.useState(false);
  const [locationEnabled, setLocationEnabled] = React.useState(true);
  const [discountCode, setDiscountCode] = React.useState(null);
  const [currentClient, setCurrentClient] = React.useState(new_client_IN);
  const [cartList, setCartList] = React.useState([]);
  const [checkoutUrl, setCheckoutUrl] = React.useState(null);
  // A function to sset the checkout ID of cart in the local storage
  const setCartID = (cartID) => {
    localStorage.setItem(localStorageKeyForCartId, cartID);
    setCartId(cartID);
  };
   function locationFind() {
    const userCurrency = localStorage.getItem("nh_country");
    if (!userCurrency) {
       navigator.geolocation.getCurrentPosition(success,error)
    }
  };
  function success(data) {
    const longi = data.coords.longitude;
    const lati = data.coords.latitude;
    localStorage.setItem(localStorageKeyForIsLocationEnabled, true);
    setLocationEnabled(true)
    if(((longi<=98.25) && (longi>=68.7)) && ((lati<=37.6) && (lati>=8.4)) ){
      switchCountry('IN')
    }
    else{
      switchCountry('US')
    }
  }
  function error(){
    const existingLocation = localStorage.getItem(localStorageKeyForIsLocationEnabled);
    if ( existingLocation === null || existingLocation === false ) {
      setLocationEnabled(false)
    }
  }


  // A function to return the current country
  const getCountry = () => country;
  // A function to switch countries
  const switchCountry = async (countryCode) => {
    // If current country is same as countryCode, the ignore
    
    if(getCountry() === countryCode) return
    // If am trying to switch to an unknown country, set it back to default country
    setLoading(true);
    if(!COUNTRY_OPTIONS.includes(countryCode)) {
      localStorage.setItem(localStorageKeyForCountry, DEFAULT_COUNTRY)
      // localStorage.removeItem(localStorageKeyForCheckoutId)
      localStorage.removeItem(localStorageKeyForCartId)
      setCountry(DEFAULT_COUNTRY)
      setCartCount(0)
      setCurrentClient(new_getClient(DEFAULT_COUNTRY));
      await new_computedCart();
      setLoading(false);
      return
    }
    localStorage.setItem(localStorageKeyForCountry, countryCode)
    // localStorage.removeItem(localStorageKeyForCheckoutId)
    setCountry(countryCode)
    localStorage.removeItem(localStorageKeyForCartId)
    setCurrentClient(new_getClient(countryCode));
    setCartCount(0)
    await new_computedCart();
    setLoading(false);
  }
  React.useEffect(() => {
    if(usBuy) {
      switchCountry('US')
    }
  }, [usBuy])
  
  // A function to get correct client based on country
  // TODO: Support multiple country going forward

  const new_getClient = (countryCode) => {
    if(countryCode === 'IN') return new_client_IN;
    return new_client_US
  }

  React.useEffect(()=>{
    locationFind();
  },[])
  // On first load, set the country to be the default country in local storage
  React.useEffect(() => {
    
    // If local storage already has a country from previous run, use it
    const countrySetOnLocalStorage = localStorage.getItem(localStorageKeyForCountry)
    
    if(countrySetOnLocalStorage && countrySetOnLocalStorage !== null) {
      setCountry(countrySetOnLocalStorage);
      setCurrentClient(new_getClient(countrySetOnLocalStorage));
      return
    }
    localStorage.setItem(localStorageKeyForCountry, DEFAULT_COUNTRY);
    setCurrentClient(new_getClient(DEFAULT_COUNTRY));
  }, [])
  
  // Get variant from product
  const getProductVariantFromHandle = async (handle) => {
    const {data,errors} = await currentClient.request(`
      query getProductByHandle($handle: String!) {
        product(handle: $handle) {
          id
          title
          description
          variants(first: 1) {
            edges {
              cursor
              node {
                id
                title
                quantityAvailable
                price {
                  amount
                  currencyCode
                }
              }
            }
          }
        }
      }
  `,{
    variables:{
      handle: handle, 
    }
  }
  );
  return {
    productVariant: data.product.variants.edges[0].node.id,
    id: data.product.id,
    title: data.product.title,
    price: data.product.variants.edges[0].node.price.amount,
    currencyCode: data.product.variants.edges[0].node.price.currencyCode,
    quantityAvailable: data.product.variants.edges[0].node.quantityAvailable
  }
  }

  const addNewItemToCart = async (productVariant, quantity) => {
    setLoading(true);
    const {data} = await currentClient.request(`
      mutation createCart($cartInput: CartInput) {
      cartCreate(input: $cartInput) {
          cart {
            id
            createdAt
            updatedAt
            checkoutUrl
            cost {
              totalAmount {
                amount
              }
            }
            lines(first: 10) {
              edges {
                node {
                  id
                  merchandise {
                    ... on ProductVariant {
                      id
                      image {
                        altText
                        id
                        url
                        height
                        width
                      }
                    }
                  }
                }
              }
            }
          }
          userErrors {
            field
            message
          }
        }
      }
        `,  {
      variables: {
         "cartInput":{
            "lines": [
              {
                "quantity": quantity,
                "merchandiseId": productVariant
              }
            ],
          }
      },
    });
    if(data.cartCreate.userErrors.length > 0){
      console.log('Error in creating cart')
      setLoading(false);
      return 
    }
    setCheckoutUrl(data.cartCreate.cart.checkoutUrl)
    setCartID(data.cartCreate.cart.id)
    await new_computedCart();
    setLoading(false);
    return {data}
  }

  // THis adds a new lineitem to an existing cart
  const addLineItemToCart = async (cartId,productVariant, quantity) => {
    setLoading(true);
    const {data} = await currentClient.request(`
      mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
          cartLinesAdd(cartId: $cartId, lines: $lines) {
            cart {
              id
            }
            userErrors {
              field
              message
            }
          }
        }
        `,  {
      variables: {
        "cartId": cartId,
        "lines": [
          {
            "merchandiseId": productVariant,
            "quantity": quantity,
          }
        ]
      },
    });
    if(data.cartLinesAdd.userErrors.length > 0){
      console.log('Error in adding line item to cart')
      setLoading(false);
      return 
    }
    await new_computedCart();
    setLoading(false);
    return {data}
  }

// this updates the quantity of a line item in the cart
  const updateCartLineItem = async (cartId, cartLineId, quantity) => {
    setLoading(true);
    const {data,errors} = await currentClient.request(`
      mutation updateCartLines($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
        cartLinesUpdate(cartId: $cartId, lines: $lines) {
          cart {
            id
            checkoutUrl
            lines(first: 10) {
              edges {
                node {
                  id
                  quantity
                  merchandise {
                    ... on ProductVariant {
                      id
                    }
                  }
                }
              }
            }
            cost {
              totalAmount {
                amount
                currencyCode
              }
            }
          }
        }
      }
    `, {
      variables: {
        "cartId": cartId,
        "lines": [
          {
            "quantity": quantity,
            "id": cartLineId,
          }
        ],
      }
    });
    if(errors && errors.graphQLErrors.length > 0){
      console.log('Error in updating cart')
      setLoading(false);
      return
    }
    await new_computedCart();
    setLoading(false);
    return {data}
  }

  // this removes an entire line item from the cart
  const removeCartLineItem = async (cartId, cartLineId) => {
    setLoading(true);
    const {data} = await currentClient.request(`
      mutation removeCartLines($cartId: ID!, $lineIds: [ID!]!) {
        cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
          cart {
            id
            lines(first: 10){
              edges
              {
                node{
                  quantity
                  merchandise{
                    ... on ProductVariant {
                      id
                    }
                  }
                }
              }
            }
            cost {
              totalAmount {
                amount
                currencyCode
              }
            }
          }
          userErrors {
            field
            message
          }
        }
      }
      `,{
        variables: {
          "cartId": cartId,
          "lineIds": [cartLineId]
        }
      });
      if(data.cartLinesRemove.userErrors.length > 0){
        console.log('Error in removing lineitem from cart')
        return 
      }
      await new_computedCart();
      setLoading(false);
      return {data}
    }

// this fetches the cart
  const getCart = async (cartId) => {
    const {data,errors} = await currentClient.request(`
      query cartQuery($cartId: ID!) {
        cart(id: $cartId) {
          id
          createdAt
          updatedAt
          checkoutUrl
          lines(first: 10) {
            edges {
              node {
                id
                quantity
                cost {
                  amountPerQuantity {
                    amount
                    currencyCode
                  }
                }
                merchandise {
                  ... on ProductVariant {
                    id
                    product {
                      title
                      } 
                    image {
                      altText
                      id
                      url
                      height
                      width
                    }
                  }
                }
              }
            }
          }
          cost {
            totalAmount {
              amount
              currencyCode
            }
          }
        }
      }
      `,{
      variables:{
        cartId: cartId
      }
      })
      if(errors && errors.graphQLErrors.length > 0){
        console.log('Error in fetching cart')
        return
      }
      setCheckoutUrl(data.cart.checkoutUrl)
      return {data}
  }

  const clearCart = async () => {
    setLoading(true);
    setCartId(null);
    setCheckoutUrl(null);
    localStorage.removeItem(localStorageKeyForCartId);
    await new_computedCart();
    setLoading(false);
  }



  const new_addHandleToCart = async (productVariant, quantity) => {
    setLoading(true);
    const existingCartId = localStorage.getItem(localStorageKeyForCartId);
    try {
      // yoo if theree is no cart ID, create a new cart with the product variant
      if (!existingCartId) {
        await addNewItemToCart(productVariant, quantity);
        notify('Item added to cart', 'success');
        setDidJustAddToCart(true);
        setCartCount((prevCount) => prevCount + quantity);
        if (!showCart) toggleCart();
      } else if (
        // If there is a cartID but the cart is empty or if it is a new item to be added to cart - add a new item to the cart
        existingCartId && 
        (cartList.length === 0 || 
          !cartList.some((item) => item.variantId === productVariant))
      ) {
        await addLineItemToCart(existingCartId, productVariant, quantity);
        notify('Item added to cart', 'success');
        setDidJustAddToCart(true);
        setCartCount((prevCount) => prevCount + quantity);
        if (!showCart) toggleCart();
      } else {
        // If there is a cart ID, get the cart and update the quantity of the product variant
        const matchingItem = cartList.find(item => item.variantId === productVariant);
        if (matchingItem) {
          await updateCartLineItem(existingCartId, matchingItem.id, matchingItem.quantity + quantity);
          notify('Item added to cart', 'success');
          setDidJustAddToCart(true);
          setCartCount((prevCount) => prevCount + quantity);
          if (!showCart) toggleCart();
        }
      }
      await new_computedCart();
    } catch (error) {
      console.error('Error during adding to cart:', error);
      notify('There was an issue adding the item to your cart.', 'error');
    } finally {
      setLoading(false);
      setTimeout(() => setDidJustAddToCart(false), 3000);
    }
};


  const notify = (message,type) =>{
    switch(type){
      case 'success':
        toast.success(message);
        break;
      case 'error':
        toast.error(message);
        break;
      case 'info':
        toast.info(message);
        break;
      case 'warn':
        toast.warn(message);
        break;
      default:
        toast(message);
    }
  } 
  // Removed the line item from from the cart (lineItemId)


  const getCartCount = () => {
    let sum = 0;
    cartList.forEach((item)=>{
      sum+=item.quantity
    })
    return sum;
  }

  const toggleCart = () => {
    setShowCart(!showCart);
  };

  const cartState = () => showCart;

  const cart = () => checkout.lineItems;

  const client = () => currentClient;


  const buyNow = async () => {
    // If cart is loading, don't update any item
    if (loading) return;
    
    const isBrowser = typeof window !== 'undefined'
    if(isBrowser) {
      if(getCartCount()!==0){
        window.open(checkoutUrl, '_blank');
        localStorage.setItem(localStorageKeyForCheckoutId, null);
        clearCart()
      }
    }
  }

  const new_computedCart =React.useCallback( async() => {
    const localCartId = localStorage.getItem(localStorageKeyForCartId)
    if (!localCartId) {
      setCartList([]);
      setCartCount(0);
      return [];
    }
    const res = await getCart(localCartId)
    if(!res.data){
      console.log('Error in computing cart')
      setCartList([]);
      setCartCount(0);
      return [];
    }
    const newCart = res.data?.cart?.lines?.edges.map((item) => ({
      variantId: item.node.merchandise.id,
      id: item.node.id,
      quantity: item.node.quantity,
      image: item.node.merchandise.image.url,
      title: item.node.merchandise.product.title,
      price: item.node.cost.amountPerQuantity.amount,
      discountApplied: false,
      discountAmount: 0,
    })) || [];
      setCartList(newCart)
      setCartCount(newCart.reduce((sum, item) => sum + item.quantity, 0));
      return newCart;
  },[currentClient])

  React.useEffect(() => {
    new_computedCart();
  }, [currentClient]);


  const computedCart = () => {
    let newCart = []
    checkout.lineItems.forEach((item, index) => {
        newCart.push({
          id: item.id,
          quantity: item.quantity,
          image: item.variant?.image?.src,
          title: item.title,
          price: item.variant?.price?.amount,
        })
    })
    return newCart
  }


  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        client,
        currentClient,
        setLoading,
        getCountry,
        switchCountry,
        new_addHandleToCart,
        removeCartLineItem,
        getCartCount,
        cartState,
        toggleCart,
        cart,
        cartId,
        setCartId,
        setCartID,
        buyNow,
        checkoutUrl,
        setCheckoutUrl,
        usBuy,
        cartCount,
        setUsBuy,
        checkout,
        loading,
        didJustAddToCart,
        setLocationEnabled,
        locationEnabled,
        getProductVariantFromHandle,
        computedCart,
        new_computedCart,
        discountCode,
        updateCartLineItem,
        setDiscountCode,
        notify,
        cartList,
        
      }}
    >
      { cartState() && < Cart />}
      { children }
    </StoreContext.Provider>
  );
};